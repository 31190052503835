<template>
  <div class="area-selector">
    <iframe class="frame" :src="`${setting.visualized.webUrl}?mode=draw`" frameborder="0"></iframe>
  </div>
</template>

<script>
import setting from '@/setting'
export default {
  data() {
    return {
      setting
    }
  },
  methods: {
    messageListenerInit() {
      let that = this
      let onMessage = function(e) {
        let { action, data } = e.data
        if (action === 'draw') {
          that.$emit('on-done', data)
        }
      }
      window.addEventListener('message', onMessage)
      this.$once('hook:beforeDestory', function() {
        window.removeEventListener('message', onMessage)
      })
    }
  },
  mounted() {
    this.messageListenerInit()
  }
}
</script>

<style lang="less" scoped>
.frame {
  height: 500px;
  width: 100%;
}
</style>
