<template>
<div class="wrap h-box-shadow">
  <el-form class="fm" label-width="190px">
    <div class="top">
      <el-button type="primary" icon="el-icon-search" @click="onSearch">{{$t('action.search')}}</el-button>      
    </div>
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-info"></i> {{$t('page.search.child_kw.m_tab.sample')}}</span>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Continent_or_Ocean')}}</template>
              <el-select v-model="fm.Continent_or_Ocean" multiple @change="onContinetOrOceanChange">
                <el-option
                  v-for="item in dict.Continent_or_Ocean"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Country')}}</template>
              <el-select v-model="fm.Country" multiple>
                <el-option
                  v-for="item in dict.Country"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Terrane')}}</template>
              <el-input v-model="fm.Terrane"></el-input>
              <!-- <el-select v-model="fm.Terrane" multiple>
                <el-option
                  v-for="item in dict.Terrane"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Rock_or_stratigraphic_unit')}}</template>
              <el-input v-model="fm.Rock_or_stratigraphic_unit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Object_or_mineral_determined')}}</template>
              <el-input v-model="fm.Object_or_mineral_determined"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Geodynamic_settings')}}</template>
              <el-select v-model="fm.Geodynamic_settings" multiple>
                <el-option
                  v-for="item in dict.Geodynamic_settings"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="地质时代最大值">
              <template slot="label">{{$t('model.sample.Max_age_limit')}}</template>
              <el-input v-model="fm.Max_age_limit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地质时代最小值">
              <template slot="label">{{$t('model.sample.Min_age_limit')}}</template>
              <el-input v-model="fm.Min_age_limit"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Geological_period')}}</template>
              <el-select v-model="fm.Geological_period" multiple>
                <el-option
                  v-for="item in dict.Geological_period"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岩性">
              <template slot="label">{{$t('model.sample.Lithology')}}</template>
              <el-select v-model="fm.Lithology" multiple>
                <el-option
                  v-for="item in dict.Lithology"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="变质级别">
              <template slot="label">{{$t('model.sample.Metamorphic_grade')}}</template>
              <el-select v-model="fm.Metamorphic_grade" multiple>
                <el-option
                  v-for="item in dict.Metamorphic_grade"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="区域选择">
              <template slot="label">区域选择</template>
              <el-input readonly :value="fm.coordinates" @click.native="onAreaSelector"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-management"></i> {{$t('page.search.child_kw.m_tab.reference')}}</span>
        <el-row :gutter="20">
          <!-- <el-col :span="12">
            <el-form-item label="文献类型">
              <template slot="label">{{$t('model.sample.Reference_type')}}</template>
              <el-input v-model="fm.Reference_type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="语言">
              <template slot="label">{{$t('model.sample.Language')}}</template>
              <el-input v-model="fm.Language"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="刊物名">
              <template slot="label">{{$t('model.sample.Journal')}}</template>
              <el-input v-model="fm.Journal"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="题名">
              <template slot="label">{{$t('model.sample.Title')}}</template>
              <el-input v-model="fm.Title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第一作者名">
              <template slot="label">{{$t('model.sample.First_Author')}}</template>
              <el-input v-model="fm.First_Author"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出版年">
              <template slot="label">{{$t('model.sample.Year')}}</template>
              <el-input v-model="fm.Year"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-opportunity"></i> {{$t('page.search.child_kw.m_tab.testMethod')}}</span>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="测试机构">
              <template slot="label">{{$t('model.sample.Place_for_experiment')}}</template>
              <el-input v-model="fm.Place_for_experiment"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="测试仪器">
              <template slot="label">{{$t('model.sample.Instrument_for_analysis')}}</template>
              <el-select v-model="fm.Instrument_for_analysis" multiple>
                <el-option
                  v-for="item in dict.Instrument_for_analysis"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="测试内容">
              <template slot="label">{{$t('model.sample.Analytical_content')}}</template>
              <el-select v-model="fm.Analytical_content" multiple>
                <el-option
                  v-for="item in dict.Analytical_content"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-timer"></i> {{$t('page.search.child_kw.m_tab.ageResult')}}</span>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="表达方式">
              <template slot="label">{{$t('model.sample.Expression')}}</template>
              <el-select v-model="fm.Expression" multiple>
                <el-option
                  v-for="item in dict.Expression"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄解释">
              <template slot="label">{{$t('model.sample.Interpretation')}}</template>
              <el-select v-model="fm.Interpretation" multiple>
                <el-option
                  v-for="item in dict.Interpretation"
                  :key="item.DicValue"
                  :label="item.DicName"
                  :value="item.DicValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.Age')}}</template>
              <el-row :gutter="0">
                <el-col :span="11">
                  <el-input v-model.number="fm.Age_begin"></el-input>
                </el-col>
                <el-col :span="2" style="text-align:center;">-</el-col>
                <el-col :span="11">
                  <el-input v-model.number="fm.Age_end"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <template slot="label">{{$t('model.sample.SE2')}}</template>
              <el-row :gutter="0">
                <el-col :span="11">
                  <el-input v-model.number="fm.SE2_begin"></el-input>
                </el-col>
                <el-col :span="2" style="text-align:center;">-</el-col>
                <el-col :span="11">
                  <el-input v-model.number="fm.SE2_end"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-form>
  <el-dialog
    class="area-selector-dialog"
    title="区域选择"
    :visible.sync="areaSelector.show"
    :close-on-click-modal="false"
    destroy-on-close
    width="900px">
    <area-selector @on-done="onAreaSelectorDone"></area-selector>
  </el-dialog>
</div>
</template>

<script>
import mapping from '@/config/mapping'
import commonApi from '@/api/common'
import AreaSelector from '@/components/visualized/area-selector'
export default {
  name: 'Search-Keyword',
  components: {
    AreaSelector
  },
  data() {
    return {
      dict: {
        Continent_or_Ocean: [], // 大陆或大洋
        Country: [], // 国家
        Terrane: [], // 地体
        Geodynamic_settings: [], // 地球动力学背景
        Lithology: [], // 岩性大类
        Instrument_for_analysis: [], // 测试仪器
        Analytical_content: [], // 测试内容
        Expression: [], // 表达方式
        Interpretation: [], // 年龄解释
        Geological_period: [], // 地质时代
        Metamorphic_grade: [] // 变质级别
      },
      fm: {
        Continent_or_Ocean: '', // 大陆或大洋
        Country: '', // 国家
        Terrane: '', // 地体
        Rock_or_stratigraphic_unit: '', // 岩石或地层单元
        Object_or_mineral_determined: '', // 测定对象或矿物
        Geodynamic_settings: '', // 地球动力学背景
        Geological_period: '', // 地质时代
        Min_age_limit: '', // 地质时代最小值
        Max_age_limit: '', // 地质时代最大值
        Lithology: '', // 岩性
        Metamorphic_grade: '', // 变质级别
        Reference_type: '', // 文献类型
        Language: '', // 语言
        Journal: '', // 刊物名
        Title: '', // 题名
        First_Author: '', // 第一作者名
        Year: '', // 出版年
        Place_for_experiment: '', // 测试机构
        Instrument_for_analysis: '', // 测试仪器
        Analytical_content: '', // 测试内容
        Expression: '', // 表达方式
        Interpretation: '', // 年龄解释
        Age_begin: '', // 年龄值
        Age_end: '', // 年龄值
        SE2_begin: '', // 误差2SE(Ma)
        SE2_end: '', // 误差2SE(Ma)
        coordinates: '', // 区域范围
      },
      tree_Continent: [], // 大洲国家关系平铺数据
      areaSelector: {
        show: false,
        val: null
      }
    }
  },
  methods: {
    async getDict() {
      const allOpt = { DicValue: '', DicName: '全部' }

      // 大陆或大洋
      const dicts = await commonApi.getVueDictionary(['tree_Continent'])
      if (dicts && dicts.length) {
        this.tree_Continent = dicts.find(item => item.dicNo === 'tree_Continent').data
        this.dict.Continent_or_Ocean = this.tree_Continent.filter(item => item.parentId === 3)
      }




      // // 国家
      // const { rows: Geodynamic_settings } = await commonApi.getDictDetail(68)
      // Geodynamic_settings.unshift(allOpt)
      // this.dict.Geodynamic_settings = Geodynamic_settings

      // // 地体
      // const { rows: Geodynamic_settings } = await commonApi.getDictDetail(68)
      // Geodynamic_settings.unshift(allOpt)
      // this.dict.Geodynamic_settings = Geodynamic_settings

      // 地球动力学背景
      const { rows: Geodynamic_settings } = await commonApi.getDictDetail(68)
      this.dict.Geodynamic_settings = Geodynamic_settings

      const { rows: Geological_period } = await commonApi.getDictDetail(90)
      this.dict.Geological_period = Geological_period

      const { rows: Lithology } = await commonApi.getDictDetail(74)
      this.dict.Lithology = Lithology

      const { rows: Instrument_for_analysis } = await commonApi.getDictDetail(76)
      this.dict.Instrument_for_analysis = Instrument_for_analysis

      const { rows: Analytical_content } = await commonApi.getDictDetail(77)
      this.dict.Analytical_content = Analytical_content

      const { rows: Expression } = await commonApi.getDictDetail(78)
      this.dict.Expression = Expression

      const { rows: Interpretation } = await commonApi.getDictDetail(79)
      this.dict.Interpretation = Interpretation

      const { rows: Metamorphic_grade } = await commonApi.getDictDetail(71)
      this.dict.Metamorphic_grade = Metamorphic_grade
    },
    onContinetOrOceanChange(val) {
      if (val && val.length) {
        let data = []
        val.forEach(valItem => {
          let parent = this.tree_Continent.find(item => item.value === valItem)
          let childs = this.tree_Continent.filter(item => item.parentId === parent.id)
          data.push(...childs)
        })
        this.dict.Country = data
      } else {
        this.dict.Country = []
        this.fm.Country = []
      }
    },
    // 组装querystring条件
    assembleQs() {
      let fm = this.fm
      // 属性查询
      let property = ``

      // 大陆或大洋
      if (fm.Continent_or_Ocean && fm.Continent_or_Ocean.length) {
        property += '<Or>'
        fm.Continent_or_Ocean.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Continent_or_Ocean']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      // 国家
      if (fm.Country && fm.Country.length) {
        property += '<Or>'
        fm.Country.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Country']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      // 地体
      if (fm.Terrane) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Terrane']}</PropertyName>
            <Literal>${fm.Terrane}</Literal>
          </PropertyIsEqualTo>`
      }
      // 岩石或地层单元
      if (fm.Rock_or_stratigraphic_unit) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Rock_or_stratigraphic_unit']}</PropertyName>
            <Literal>${fm.Rock_or_stratigraphic_unit}</Literal>
          </PropertyIsEqualTo>`
      }
      // 测定对象或矿物
      if (fm.Object_or_mineral_determined) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Object_or_mineral_determined']}</PropertyName>
            <Literal>${fm.Object_or_mineral_determined}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Geodynamic_settings && fm.Geodynamic_settings.length) {
        property += '<Or>'
        fm.Geodynamic_settings.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Geodynamic_settings']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Geological_period && fm.Geological_period.length) {
        property += '<Or>'
        fm.Geological_period.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Geological_period']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Min_age_limit) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Min_age_limit']}</PropertyName>
            <Literal>${fm.Min_age_limit}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Max_age_limit) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Max_age_limit']}</PropertyName>
            <Literal>${fm.Max_age_limit}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Lithology && fm.Lithology.length) {
        property += '<Or>'
        fm.Lithology.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Lithology']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Metamorphic_grade && fm.Metamorphic_grade.length) {
        property += '<Or>'
        fm.Metamorphic_grade.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Metamorphic_grade']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Reference_type) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Reference_type']}</PropertyName>
            <Literal>${fm.Reference_type}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Language) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Language']}</PropertyName>
            <Literal>${fm.Language}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Journal) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Journal']}</PropertyName>
            <Literal>${fm.Journal}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Title) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Title']}</PropertyName>
            <Literal>${fm.Title}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.First_Author) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['First_Author']}</PropertyName>
            <Literal>${fm.First_Author}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Year) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Year']}</PropertyName>
            <Literal>${fm.Year}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Place_for_experiment) {
        property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Place_for_experiment']}</PropertyName>
            <Literal>${fm.Place_for_experiment}</Literal>
          </PropertyIsEqualTo>`
      }
      if (fm.Instrument_for_analysis && fm.Instrument_for_analysis.length) {
        property += '<Or>'
        fm.Instrument_for_analysis.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Instrument_for_analysis']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Analytical_content && fm.Analytical_content.length) {
        property += '<Or>'
        fm.Analytical_content.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Analytical_content']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Expression && fm.Expression.length) {
        property += '<Or>'
        fm.Expression.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Expression']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Interpretation && fm.Interpretation.length) {
        property += '<Or>'
        fm.Interpretation.forEach(val => {
          property += `
          <PropertyIsEqualTo>
            <PropertyName>${mapping.visualized.sample['Interpretation']}</PropertyName>
            <Literal>${val}</Literal>
          </PropertyIsEqualTo>`
        })
        property += '</Or>'
      }
      if (fm.Age_begin || fm.Age_end) {
        property += `
        <PropertyIsBetween>
          <PropertyName>${mapping.visualized.sample['Age']}</PropertyName>
          <LowerBoundary><Literal>${fm.Age_begin}</Literal></LowerBoundary>
          <UpperBoundary><Literal>${fm.Age_end}</Literal></UpperBoundary>
        </PropertyIsBetween>`
      }
      if (fm.SE2_begin || fm.SE2_end) {
        property += `
        <PropertyIsBetween>
          <PropertyName>${mapping.visualized.sample['SE2']}</PropertyName>
          <LowerBoundary><Literal>${fm.SE2_begin}</Literal></LowerBoundary>
          <UpperBoundary><Literal>${fm.SE2_end}</Literal></UpperBoundary>
        </PropertyIsBetween>`
      }

      // 空间查询
      // let coordinates = `12726459.460965317,3419486.8968096212 12757034.272973508,3185895.3384609763 12969834.959628584,3216470.1504073865 13119040.038241616,3420709.8904287424 12726459.460965317,3419486.8968096212 12726459.460965317,3419486.8968096212`
      let intersects = ''
      if (this.areaSelector.val) {
        if (this.areaSelector.val.geometryType === 'esriGeometryPoint') {
          // 点
          intersects = `
          <Intersects>
            <PropertyName>the_geom</PropertyName>
            <gml:Point srsName='EPSG:3857'>
              <gml:coordinates>${this.fm.coordinates}</gml:coordinates>
            </gml:Point>
          </Intersects>`
        } else if (this.areaSelector.val.geometryType === 'esriGeometryPolyline') {
          // 线
          intersects = `
          <Intersects>
            <PropertyName>the_geom</PropertyName>
            <gml:LineString srsName='EPSG:3857'>
              <gml:coordinates>${this.fm.coordinates}</gml:coordinates>
            </gml:LineString>
          </Intersects>`
        } else if (this.areaSelector.val.geometryType === 'esriGeometryPolygon') {
          // 面
          intersects = `
          <Intersects>
            <PropertyName>the_geom</PropertyName>
            <gml:Polygon srsName='EPSG:3857'>
              <gml:outerBoundaryIs>
                <gml:LinearRing>
                  <gml:coordinates>${this.fm.coordinates}</gml:coordinates>
                </gml:LinearRing>
              </gml:outerBoundaryIs>
            </gml:Polygon>
          </Intersects>`
        }
      }
      let filter = ''
      if (property || intersects) {
        filter = `
        <Filter xmlns='http://www.opengis.net/ogc' xmlns:gml='http://www.opengis.net/gml'>
          <And>
            ${intersects}
            ${property}
          </And>
        </Filter>`
      }

      let qs = ``
      qs += `service=WFS`
      qs += `&version=1.0.0`
      qs += `&request=GetFeature` // 请求方式
      qs += `&typeName=zkyxm:testPoint` // 服务名
      //qs += `&srsName=EPSG:3857` // 返回坐标系
      qs += `&outputFormat=application/json` // 返回格式
      qs += `&maxFeatures=2000` // 返回最大记录数
      qs += `&filter=${filter}` // 查询过滤器
      // qs = qs.replace(/\s+/g, '') // 去除空格
      return qs
    },
    onSearch() {
      let qs = this.assembleQs()
      this.$router.push(`/search/visualized-result?${qs}`)
    },
    onAreaSelector() {
      this.areaSelector.show = true
    },
    onAreaSelectorDone(data) {
      // console.log(data)
      this.areaSelector.show = false
      this.areaSelector.val = data
      if (data.geometryType === 'esriGeometryPoint') {
        let geometry = data.features[0].geometry
        this.fm.coordinates = `${geometry.x},${geometry.y}`
      } else if (data.geometryType === 'esriGeometryPolyline') {
        let arr = data.features[0].geometry.paths.map(item => {
          return `${item[0]},${item[1]}`
        })
        this.fm.coordinates = arr.join(' ')
      } else if (data.geometryType === 'esriGeometryPolygon') {
        let arr = data.features[0].geometry.rings[0].map(item => {
          return `${item[0]},${item[1]}`
        })
        arr.push(arr[0])
        this.fm.coordinates = arr.join(' ')
      }
    }
  },
  mounted() {
    this.getDict()
  }
}
</script>

<style lang="less" scoped>
  .wrap {
    background-color: #fff;
    .top {
      padding: 16px;
      text-align: right;
    }
  }
  .fm {
    /deep/ .el-select {
      width: 100%;
    }
  }
  .area-selector-dialog {
    /deep/ .el-dialog__body {
      padding: 0;
    }
  }
</style>
